.center {
  width: 100%;
}

#NamuHome {
  //overflow: hidden;
}

.input {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}

.input input {
  display: none !important;
}

.input-disabled {
  cursor: no-drop;
}

.my-slick-slider .slick-slide {
  border: none !important;
}

.main-contents-dots {
  display: flex;
  flex-direction: column;
}

.custom-dots {
  position: absolute;
  top: -50%; /* 이미지의 수직 중앙에 맞추기 위해 */
  /*right: 0; !* 오른쪽 정렬 *!*/
  left: 48%;
  transform: translateY(-50%); /* Y축 기준 중앙 정렬 */
  text-align: right; /* Dots 오른쪽 정렬 */
}

.privacy-policy {
  li {
    margin-bottom: 0.1rem;
  }
}

// 근로자 파견 사업의 마진율 공시 추가 -김한-
.worker-dispatching-act {
  li {
    margin-bottom: 0.1rem;
    text-indent: 0.5rem; /* 숫자앞에 살짝 들여쓰기 */
  }

}