.detail_box {
  @apply flex flex-col text-[#444444] gap-4;
  div {
    @apply flex gap-4;
    img {
      @apply w-4 lg:w-5;
    }
    h4 {
      @apply font-bold;
    }
  }
  p {
    @apply text-sm font-medium;
  }
}