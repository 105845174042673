.grid_box {
  @apply grid grid-cols-3 border-[4px] border-[#00C06E] rounded-3xl w-[17rem] h-[17rem] p-3 gap-6
  md:w-[20rem] md:h-[20rem]
  xl:w-[28rem] xl:h-[28rem]
  ;

  div {
    @apply w-[64px] h-[64px] rounded-full flex justify-center items-center
    md:w-[80px] md:h-[80px]
    xl:w-[120px] xl:h-[120px]
  }
  div:nth-of-type(2n) {
    @apply bg-[#214F3D] text-white font-bold
  }
  div:nth-of-type(2n + 1) {
    @apply bg-[#D0E6DC] text-white
  }
  div:nth-of-type(5) {
    @apply bg-white border-[3px] border-[#00C06E]
  }
  p {
    @apply text-[0.48rem] text-center
    md:text-[0.6rem]
    xl:text-[0.9rem]
  }
  img {
    @apply w-[47.5%]
  }
  .namu_logo {
    @apply w-[80%] pb-2 pl-1
  }
}