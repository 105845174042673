@import url('./assets/fonts/font.scss');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  letter-spacing: 0.07rem;
}

*, ::after, ::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: MPLUS1; // 페이지 통일 폰트
}

/* 스크롤 바 전체의 너비 설정 */
::-webkit-scrollbar {
  width: 0.4rem;
}

/* 스크롤 바 트랙(바탕) 스타일 */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* 스크롤 바 핸들(움직이는 부분) 스타일 */
::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 20rem;
}

/* 스크롤 바 핸들에 호버했을 때 스타일 */
::-webkit-scrollbar-thumb:hover {
  background: #6c6c6c;
}

/*슬라이더 컨테이너 크기 별로 지정 10% 20% 30% 100%*/
.slider-container-100 {
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 959px) {
  /* 스크롤 바 전체의 너비 설정 */
  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  /* 스크롤 바 트랙(바탕) 스타일 */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* 스크롤 바 핸들(움직이는 부분) 스타일 */
  ::-webkit-scrollbar-thumb {
    background: #a9a9a9;
    border-radius: 20rem;
  }

  /* 스크롤 바 핸들에 호버했을 때 스타일 */
  ::-webkit-scrollbar-thumb:hover {
    background: #6c6c6c;
  }

  /*슬라이더 컨테이너 크기 별로 지정 10% 20% 30% 100%*/
  .slider-container-100 {
    margin: 0 auto;
    width: 100%;
  }
}

address {
  font-style: normal; /* 기울임 해제 */
}

.main-service-bg {
  background: transparent linear-gradient(180deg, #F7F7F7 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}
.employment-selection-process-bg {
  background: transparent linear-gradient(180deg, #DFEAF4 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}
.hide-scrollbar {
  scrollbar-width: none;
}
.snap-x-mandatory {
  scroll-snap-type: x mandatory
}
@layer components {
  .service-main-mold {
    @apply flex justify-around overflow-hidden relative flex-col-reverse mt-4 pt-8 pb-8 md:mt-24 md:pt-16 md:pb-8;
  }
}
