.file_type::after {
  content: "";
  position: absolute;
  transform: scale(4.3, 3.8);
  top: 0;
  transform-origin: center center;
  width: 100%;
  height: 100%;
  background-image: url('../../../../../../assets/img/minerva/fileType/mobile_minerva_purplebg.png');
  background-size: contain;
  overflow: visible;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -100;
}
