//M PLUS 1
@font-face {
  font-family: MPLUS1;
  font-weight: 100;
  src: local(MPLUS1),
  url("./M_PLUS_1/MPLUS1-Thin.ttf") format("truetype");
}

@font-face {
  font-family: MPLUS1;
  font-weight: 200;
  src: local(MPLUS1),
  url("./M_PLUS_1/MPLUS1-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: MPLUS1;
  font-weight: 300;
  src: local(MPLUS1),
  url("./M_PLUS_1/MPLUS1-Light.ttf") format("truetype");
}

@font-face {
  font-family: MPLUS1;
  font-weight: normal;
  src: local(MPLUS1),
  url("./M_PLUS_1/MPLUS1-Regular.ttf") format("truetype");
}

@font-face {
  font-family: MPLUS1;
  font-weight: 500;
  src: local(MPLUS1),
  url("./M_PLUS_1/MPLUS1-Medium.ttf") format("truetype");
}

@font-face {
  font-family: MPLUS1;
  font-weight: 600;
  src: local(MPLUS1),
  url("./M_PLUS_1/MPLUS1-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: MPLUS1;
  font-weight: 700;
  src: local(MPLUS1),
  url("./M_PLUS_1/MPLUS1-Bold.ttf") format("truetype");
}

@font-face {
  font-family: MPLUS1;
  font-weight: 800;
  src: local(MPLUS1),
  url("./M_PLUS_1/MPLUS1-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: MPLUS1;
  font-weight: 900;
  src: local(MPLUS1),
  url("./M_PLUS_1/MPLUS1-Black.ttf") format("truetype");
}
