.solution_service_bluebg::after {
  content: "";
  position: absolute;
  transform: scale(3.3, 3.0);
  top: 0;
  transform-origin: center center;
  width: 100%;
  height: 100%;
  background-image: url('../../../../../../assets/img/minerva/solutionService/mobile_minerva_bluebg.png');
  background-size: contain;
  overflow: visible;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -100;
}
.solution_service_greenbg::after {
  content: "";
  position: absolute;
  transform: scale(2.5, 2.0);
  top: 0;
  transform-origin: center center;
  width: 100%;
  height: 100%;
  background-image: url('../../../../../../assets/img/minerva/solutionService/mobile_minerva_greenbg.png');
  background-size: contain;
  overflow: visible;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -100;
}
.div_mask {
  background-color: #ffffff;
  mask-image: radial-gradient(65% 85%, rgba(0, 0, 0, 1.0) 40%, rgba(0, 0, 0, 0) 77%);
}
